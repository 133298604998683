<template>
  <v-footer padless class="aa-footer">
    <div class="aa-footer__content">
      <div class="aa-footer__content-wrapper">
        <div class="aa-footer__content-card">
          <router-link :to="{ name: 'Home' }">
            <img class="logo" src="/images/AA-logo.svg" />
          </router-link>

          <ul class="aa-footer__content-social">
            <li v-for="social in socials" :key="social.id">
              <a :href="social.url" target="_blank" rel="noopener">
                <img
                  :src="`/images/icons/${social.name}.svg`"
                  :alt="social.name"
                />
              </a>
            </li>
          </ul>
          <ul class="aa-footer__content-contacts">
            <li v-for="contact in contacts" :key="contact.id">
              <a :href="contact.href">
                <img
                  :src="`/images/icons/${contact.icon}`"
                  :alt="contact.title"
                />
                {{ contact.name }}
              </a>
            </li>
          </ul>
        </div>

        <ul class="aa-footer__content-nav">
          <li v-for="item in nav" :key="item.id">
            <router-link @click.native="scrollToTop" active-class="active" :to="{ name: item.url }"
              >{{ item.name }}
            </router-link>
          </li>
        </ul>

        <div class="aa-footer__content__copyright">
          <span>Copyright © 2024 Archival Arts Inc.</span>
        </div>
        <div class="aa-footer__content__dev">
          <span>
            <a href="https://emsoftmn.com/">Web Development</a> by EMSoft
          </span>
        </div>
      </div>
    </div>
    <div class="aa-footer__newsletter">
      <div class="aa-footer__newsletter-wrapper">
        <div class="aa-footer__newsletter-form">
          <h2>Join Our Newsletter</h2>
          <p>Discover new art and collections added weekly by our artists.</p>
          <v-form ref="form" @submit.prevent="submit">
            <v-text-field
              placeholder="Email address"
              v-model="email"
              required
              :rules="emailRules"
              type="email"
            ></v-text-field>

            <button type="submit" class="btn btn-secondary arrow">
              Subscribe
            </button>
          </v-form>
        </div>
        <div class="aa-footer__newsletter-dev">
          <span>
            <a href="https://emsoftmn.com/">Web Development</a> by EMSoft
          </span>
        </div>
      </div>
    </div>
  </v-footer>
</template>

<script>
import { getError } from "@/utils/helpers";
import NewsletterService from "@/services/NewsletterService";

export default {
  name: "Footer",
  data: () => {
    return {
      email: null,
      errors: {},
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
      links: ["About Us", "Services", "Contact Us"],
      socials: [
        { name: "instagram", url: "https://www.instagram.com/mdartgalleries" },
        { name: "facebook", url: "https://www.facebook.com/mdartgalleries" },
        {
          name: "linkedin",
          url: "https://www.linkedin.com/company/mdartgalleries/",
        },
      ],
      nav: [
        { name: "About Us", url: "About" },
        { name: "Art Categories", url: "ArtCategories" },
        { name: "Artist Archive", url: "Artist Archive" },
        { name: "Contact Us", url: "Contact" },
        { name: "Return Policy", url: "ReturnPolicy" },
        { name: "Privacy Policy", url: "PrivacyPolicy" },
        { name: "FAQ", url: "FAQ" },
      ],
      contacts: [
        {
          name: "info@mdartgalleries.com",
          icon: "Subtract.svg",
          href: "mailto:info@mdartgalleries.com",
          title: "Archival Arts Email",
        },
        {
          name: "(410) 247-7771",
          icon: "call.svg",
          href: "tel:4102477771",
          title: "Archival Arts Phone",
        },
      ],
      emailRules: [
        // (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    async submit() {
      const valid = await this.$refs.form.validate();

      if (valid) {
        const data = {
          email: this.email,
        };

        try {
          const res = NewsletterService.newsletterForm(data);
          console.log(res);

          this.$store.dispatch("snackbar/showSnack", {
            text: "Newsletter Successful.",
            color: "success",
            timeout: 2000,
          });
        } catch (error) {
          console.log(error);
          this.errors = getError(error);

          this.$store.dispatch("snackbar/showSnack", {
            text: "This Form Has Errors.",
            color: "error",
            timeout: 2000,
          });
        }

        await this.$refs.form.reset();
      }
    },
  },
  async created() {},
};
</script>

<style lang="scss">
@import "./footer-style.scss";
</style>
