var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"aa-fresh-slider container-out"},[_c('div',{staticClass:"aa-fresh-slider__wrapper"},[_c('div',{staticClass:"aa-fresh-slider__content"},[_c('div',{staticClass:"aa-fresh-slider__content-wrapper"},[_c('h2',{staticClass:"aa-fresh-slider__title"},[_vm._v(" "+_vm._s(_vm.filteredField.slider_section_title)+" ")]),_c('p',{staticClass:"aa-fresh-slider__des"},[_vm._v(" "+_vm._s(_vm.filteredField.slider_section_description)+" ")])])]),_c('div',{staticClass:"aa-fresh-slider__buttons"},[_c('div',{staticClass:"aa-fresh-slider__buttons-wrapper"},[_c('router-link',{staticClass:"btn btn-primary__circle",attrs:{"to":"/art-categories"}},[_vm._v(" "+_vm._s(_vm.filteredField.slider_section_button_text)+" ")]),_c('v-flex',[_c('span',{staticClass:"btn btn-secondary__circle prev",on:{"click":function($event){return _vm.$refs.prev.click()}}}),_c('span',{staticClass:"btn btn-secondary__circle",on:{"click":function($event){return _vm.$refs.next.click()}}})])],1)]),_c('div',{staticClass:"aa-fresh-slider__carousel"},[(_vm.filteredSlider)?_c('carousel',{attrs:{"autoplaySpeed":3000,"autoWidth":true,"autoplay":true,"margin":30,"dots":false,"loop":true,"items":4,"nav":false,"responsive":{
          320: { items: 1, autoWidth: false, center: true, dots: true },
          576: { items: 2, dots: true, center: true },
          768: { items: 3, margin: 20 },
          1200: { items: 4 },
        }}},[_vm._l((_vm.filteredSlider),function(item){return _c('div',{key:item.id,staticClass:"aa-fresh-slider__carousel-item"},[_c('div',{staticClass:"aa-fresh-slider__carousel-item-img"},[_c('a',{attrs:{"href":`/order?image_id=${item.id}&mode=create`}},[_c('img',{attrs:{"alt":item.name,"title":item.name,"src":item.preview_image.path
                    ? item.preview_image.path
                    : '/images/placehold.webp'}})])]),_c('div',[_c('a',{attrs:{"href":`/order?image_id=${item.id}&mode=create`}},[_vm._v(" "+_vm._s(item.name)+" ")])])])}),_c('template',{slot:"prev"},[_c('span',{ref:"prev",staticClass:"prev",attrs:{"hidden":""}},[_vm._v("prev")])]),_c('template',{slot:"next"},[_c('span',{ref:"next",staticClass:"next",attrs:{"hidden":""}},[_vm._v("next")])])],2):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }