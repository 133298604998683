<template>
  <section class="aa-banner">
    <v-container>
      <div class="aa-banner__wrapper">
        <div class="aa-banner__overlay">
          <div class="aa-banner__overlay-wrapper"></div>
        </div>
        <div class="aa-banner__title">
          <h1
            class="hero"
            id="aa-banner__title"
            v-if="filteredField.first_section_title"
          >
            {{ filteredField.first_section_title }}
          </h1>
          <p v-if="filteredField.first_section_subtitle">
            {{ filteredField.first_section_subtitle }}
          </p>
          <div class="aa-banner__btn">
<!--            <router-link-->
<!--              :to="{ name: 'Login' }"-->
<!--              class="btn btn-primary arrow"-->
<!--              v-if="filteredField.first_section_left_btn_text"-->
<!--              >{{ filteredField.first_section_left_btn_text }}-->
<!--            </router-link>-->
            <router-link
              :to="{ name: 'ArtCategories' }"
              class="btn btn-primary arrow"
              v-if="filteredField.first_section_right_btn_text"
              >{{ filteredField.first_section_right_btn_text }}
            </router-link>
          </div>
          <div class="aa-banner__video">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/9Fw_Gm3rTOk?si=h5kOUtf5K95f2KkR" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
        <div class="aa-banner__gallery">
          <div
            v-for="(image, index) in filteredCategories"
            :key="image.id"
            class="aa-banner__gallery-item"
            :class="index == 2 ? 'active' : ''"
          >
            <router-link
              :to="{
                name: 'ArtCategories',
                query: { subject: image.id },
              }"
            >
              <v-img
                :src="
                  image.path ? $func.src(image.path) : 'images/placehold.webp'
                "
                lazy-src="/images/placehold.webp"
                aspect-ratio="1"
                class="aa-banner__gallery-img"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="red"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </router-link>
            <div>{{ image.name }}</div>
          </div>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Banenr",
  data: () => {
    return {
      fields: "",
      flag: false,
      newCat: null,
      banner: [],
    };
  },
  computed: {
    ...mapGetters("homepage", ["error", "loading", "field", "homestorage"]),
    ...mapGetters("homeCategories", ["categories", "homecategories"]),
    filteredField: function() {
      if (this.field || this.homestorage) {
        this.$store.dispatch("loader/showLoader", {
          loading: false,
        });

        return this.homestorage ? this.homestorage : this.field;
      }

      return false;
    },
    filteredLoading: function() {
      if (!this.loading || this.loading === "") {
        return this.loading;
      }
      return this.loading;
    },
    filteredCategories: function() {
      if (!this.categories || this.categories === "") {
        return this.homecategories ? this.homecategories : this.categories;
      }

      return this.homecategories ? this.homecategories : this.categories;
    },
  },
};
</script>

<style lang="scss">
@import "./main-style";
</style>
