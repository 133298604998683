<template>
  <header app class="header aa-header" :class="{ active: isActive }">
    <v-container>
      <div class="aa-header__wrapper aa-header__wrapper_desktop">
        <router-link :to="{ name: 'Home' }" class="aa-header__logo">
          <img class="logo" src="/images/AA-logo.svg" />
        </router-link>

        <nav class="aa-header__menu">
          <ul>
            <li v-for="item in links" :key="item.id">
              <router-link active-class="active" :to="item.url">
                {{ item.name }}
                <span
                  v-show="item.subitem"
                  class="v-icon mdi mdi-menu-down "
                ></span>
              </router-link>

              <div class="aa-header__menu-subitem" v-if="item.subitem">
                <router-link
                  :to="subitem.url"
                  v-for="subitem in item.subitem"
                  :key="subitem.id"
                >
                  {{ subitem.name }}
                </router-link>
              </div>
            </li>

            <li v-if="!authUser">
              <router-link
                active-class="active"
                alt="Archival Arts Logo"
                :to="{ path: '/login' }"
              >
                Log In
              </router-link>
            </li>
          </ul>
        </nav>

        <div class="header-search-wrap">
          <input
              v-model="headerSearch"
              class="header-search"
              placeholder="Search by keyword or artist"
              type="text"
              @keydown.enter="isSearch"
              :disabled="this.pageLoading"
          />
        </div>

        <div class="aa-header__toolbar" :class="{ is_login: authUser }">
          <Cart />
          <AccountMenu v-show="authUser" />

          <button class="aa-header__burger" @click="burgerActive">
            <svg id="svg" height="35px" viewBox="0 0 32 25.5" width="35px" x="0px" xml:space="preserve"
                 xmlns="http://www.w3.org/2000/svg" y="0px">
              <g class="svg-menu-toggle">
              <path class="bar" d="M20.945,8.75c0,0.69-0.5,1.25-1.117,1.25H3.141c-0.617,0-1.118-0.56-1.118-1.25l0,0c0-0.69,0.5-1.25,1.118-1.25h16.688C20.445,7.5,20.945,8.06,20.945,8.75L20.945,8.75z" />
              <path class="bar" d="M20.923,15c0,0.689-0.501,1.25-1.118,1.25H3.118C2.5,16.25,2,15.689,2,15l0,0c0-0.689,0.5-1.25,1.118-1.25 h16.687C20.422,13.75,20.923,14.311,20.923,15L20.923,15z"/>
              <path class="bar" d="M20.969,21.25c0,0.689-0.5,1.25-1.117,1.25H3.164c-0.617,0-1.118-0.561-1.118-1.25l0,0c0-0.689,0.5-1.25,1.118-1.25h16.688C20.469,20,20.969,20.561,20.969,21.25L20.969,21.25z" />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div class="aa-header__wrapper aa-header__wrapper_mob pt-3">
        <input
            v-model="headerSearch"
            class="header-search"
            placeholder="Search by keyword or artist"
            type="text"
            @keydown.enter="isSearch"
            :disabled="this.pageLoading"
        />
      </div>
    </v-container>
  </header>
</template>

<script>
// import MainMenu from "@/components/MainMenu.vue";
import Cart from "@/components/ToolsBar/Cart.vue";
import AccountMenu from "@/components/ToolsBar/AccountMenu.vue";

import { mapGetters } from "vuex";

export default {
  name: "Header",
  data: () => ({
    headerSearch: "",
    pageLoading: false,
    isActive: false,
    noScroll: document.querySelector("html").classList.remove("on-scroll"),
    value: 1,
    links: [
      { url: "/about", name: "About Us" },
      {
        url: "/art-categories",
        name: "Art Categories",
        subitem: [{ url: "/categories", name: "All Categories" }],
      },
      { url: "/artist-archive", name: "Artist Archive" },
      { url: "/contact-us", name: "Contact" },
    ],
  }),
  created: async function() {
    const currentQuery = this.$route.query;
    if (!!currentQuery.search && currentQuery.search.length) {
      this.headerSearch = currentQuery.search;
    }

    if (this.authUser) {
      await this.$store.dispatch("auth/getAuthUser");
    }
  },
  components: { Cart, AccountMenu },
  computed: {
    ...mapGetters("auth", ["loading", "error", "authUser"]),
  },
  methods: {
    isSearch: function() {
      window.location.href = "/art-categories?search=" + this.headerSearch;
    },
    burgerActive: async function() {
      this.isActive = !this.isActive;
      document.querySelector("html").classList.toggle("on-scroll");
    },
    burgerResize: function() {
      const width = document.documentElement.clientWidth;
      if (width > 1200) {
        this.noScroll;
        this.isActive = false;
      }
    },
  },
  async mounted() {
    await window.addEventListener("resize", this.burgerResize);
  },
  watch: {
    $route() {
      this.isActive = false;
      document.querySelector("html").classList.remove("on-scroll");
    },
  },
  // updated() {
  //   console.log(this.$route);
  // },
};
</script>

<style lang="scss">
@import "./header-style.scss";
</style>
