<template>
  <div class="aa-art-categories ">
    <v-overlay
      :value="overlay"
      @click="isOverlay"
      @click.stop="drawer = !drawer"
      ref="overlaySidebar"
    ></v-overlay>
    <div class="aa-art-categories__banner">
      <v-container>
        <div class="aa-art-categories__banner-wrapper">
          <h1>{{ this.content.title }}</h1>
          <p class="ma-0">
            {{ this.content.desc }}
          </p>
        </div>
      </v-container>
    </div>
    <div class="aa-art-categories__list">
      <v-container>
        <div class="aa-art-categories__list-wrapper">
          <SidebarArtCategories
            :items="drawer"
            :paramsCategory="this.$route.query.subject"
            @close="closeSidebar"
            @options="isSidebar"
          />

          <div class="aa-art-categories__list-main">
            <div
              class="aa-art-categories__overlay"
              :class="{ active: this.pageLoading }"
            ></div>
            <div class="aa-art-categories__list-search">
              <label
                >Can type multiple keywords
                <input
                  v-model="search"
                  placeholder="Search"
                  type="text"
                  ref="searchText"
                  id="input-search"
                  @keydown.enter="$refs.searchBtn.click()"
                  :disabled="this.pageLoading"
                />
              </label>
              <div class="aa-art-categories__list-tools">
                <button
                  class="aa-art-categories__list-filter btn-secondary arrow"
                  @click.stop="drawer = !drawer"
                  @click="isFilterBtn"
                >
                  <svg
                    width="26"
                    height="29"
                    viewBox="0 0 26 29"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M22.66 0.333252H3.33999C0.876173 0.333252 -0.593402 3.04694 0.773278 5.0729L8.87901 15.6721C9.55466 16.6737 9.9152 17.8505 9.9152 19.0542V27.1392C9.9152 28.4972 11.5766 29.1773 12.5482 28.2171L15.633 25.1685C15.9223 24.8826 16.0848 24.4949 16.0848 24.0906V19.0542C16.0848 17.8505 16.4453 16.6737 17.121 15.6721L25.2267 5.0729C26.5934 3.04695 25.1238 0.333252 22.66 0.333252Z"
                      fill="#9D2235"
                    />
                    <path
                      d="M22.66 0.333252H3.33999C0.876173 0.333252 -0.593402 3.04694 0.773278 5.0729L8.87901 15.6721C9.22149 16.1798 9.48301 16.7325 9.65764 17.3109L16.3352 17.335C16.5098 16.7478 16.7738 16.1868 17.121 15.6721L25.2267 5.0729C26.5934 3.04695 25.1238 0.333252 22.66 0.333252Z"
                      fill="#9D2235"
                    />
                  </svg>Filter
                </button>
                <button
                  @click="isPagination"
                  ref="searchBtn"
                  class="btn btn-primary arrow"
                  id="btn-click-search"
                  :disabled="this.pageLoading"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="aa-art-categories__list-masonry-layout">
              <div
                v-show="
                  this.pageData.length === 0 &&
                    this.pageData &&
                    !this.pageLoading
                "
              >
                <h2>No results</h2>
              </div>

              <vue-masonry-wall
                :ssr="{ columns: 0 }"
                :items="pageData"
                :options="{ width: 300, padding: 0 }"
                @append="append"
                v-if="this.pageRefresh && this.pageData.length !== 0"
              >
                <template v-slot:default="{ item }">
                  <div class="item" v-if="item">
                    <div class="aa-art-categories__list-image">
                      <a :href="`/order?image_id=${item.id}&mode=create`">
                        <img
                          :src="
                            item.preview_image.web_path
                              ? item.preview_image.web_path
                              : '/images/placehold.webp'
                          "
                          :alt="item && item.name"
                          :title="item && item.name"
                        />
                        <div class="aa-art-categories__list-buttons">
                          <i class="aa-icon aa-icon-arrow"></i>
                        </div>
                      </a>
                    </div>
                    <h4>
                      <a :href="`/order?image_id=${item.id}&mode=create`">
                        {{ item.name ? item.name : "Untitled" }}
                      </a>
                    </h4>
                  </div>
                </template>
              </vue-masonry-wall>

              <v-row
                class="fill-height pa-md-16 aa-loading"
                align="center"
                justify="center"
                v-if="this.pageLoading && this.pageData"
              >
                <v-progress-circular
                  indeterminate
                  color="red"
                  :size="100"
                  :width="10"
                  >Loading</v-progress-circular
                >
              </v-row>
            </div>

            <div
              class="v-pagination__inner"
              v-if="this.pageLength > 1 && this.pageRefresh"
            >
              <v-pagination
                v-model="pageCurrent"
                :length="this.pageLength"
                @input="isPagination"
                :disabled="this.pageLoading"
                :total-visible="6"
                color="none"
                @click.native="scrollToTop"
              ></v-pagination>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import SidebarArtCategories from "@/components/Sidebar/SidebarArtCategories.vue";
import ArtCategories from "@/services/ArtCategories.js";
import VueMasonryWall from "vue-masonry-wall";

export default {
  data: () => ({
    pageData: [],
    pageCurrent: 1,
    pageLength: null,
    renderComponent: true,
    pageLoading: false,
    pageRefresh: true,
    overlay: false,
    drawer: false,
    search: "",
    searchGlobal: "",
    options: "",
    content: {
      title: "Art Categories",
      desc:
        "Narrow your search to find the perfect piece. You can choose size, media and other options once you choose an image. Happy searching!",
    },
  }),

  components: { SidebarArtCategories, VueMasonryWall },
  mounted() {
    window.addEventListener("resize", this.forceOverlay);
  },
  created: async function() {
    if (this.$route.query.subject) {
      this.options += "&subject[]=" + this.$route.query.subject;
    }

    const currentQuery = this.$route.query;
    if (!!currentQuery.search && currentQuery.search.length) {
      this.search = currentQuery.search;
      this.searchGlobal = '&search=' + currentQuery.search;
    }
    try {
      const image = await ArtCategories.getImages(
        this.pageCurrent,
        this.searchGlobal,
        this.options
      );

      this.pageData = image.data;
      this.pageLength = image.last_page;
    } catch (err) {
      console.log(err);
    }

    this.$store.dispatch("loader/showLoader", {
      loading: false,
    });
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    async isSidebar(e) {
      this.options = "";
      this.pageCurrent = 1;
      this.pageRefresh = false;
      this.pageLoading = true;
      if (this.search.length > 0) this.searchGlobal == this.search;
      else this.searchGlobal = "";

      if (e.medium.length !== 0) {
        let medium = "&medium[]=" + e.medium.join("&medium[]=");

        this.options += medium;
      }

      if (e.proportion.length !== 0) {
        let proportion = "&proportion[]=" + e.proportion.join("&proportion[]=");

        this.options += proportion;
      }

      if (e.style.length !== 0) {
        let style = "&style[]=" + e.style.join("&style[]=");

        this.options += style;
      }

      if (e.subject.length !== 0) {
        let subject = "&subject[]=" + e.subject.join("&subject[]=");
        this.options += subject;
      }

      try {
        const image = await ArtCategories.getImages(
          this.pageCurrent,
          this.searchGlobal,
          this.options
        );

        if (image) {
          this.pageData = image.data;
          this.pageLength = image.last_page;
          this.pageCurrentFlag = image.current_page;
        }
        this.pageRefresh = true;
      } catch (err) {
        console.log(err);
      }
      this.pageLoading = false;

      this.$store.dispatch("loader/showLoader", {
        loading: false,
      });
    },
    async isPagination(page) {
      this.searchGlobal = this.search;
      if (typeof page !== "number") {
        this.pageCurrent = 1;
      }
      if (this.searchGlobal === "") {
        this.searchGlobal = "";
        console.log("empty search");
      } else {
        console.log("full search");
        this.searchGlobal = "&search=" + this.searchGlobal;
      }


      if (this.pageCurrentFlag != page) {
        this.pageLoading = true;
        try {
          const image = await ArtCategories.getImages(
            this.pageCurrent,
            this.searchGlobal,
            this.options
          );

          if (image) {
            this.pageData = image.data;
            this.pageLength = image.last_page;
            this.pageCurrentFlag = image.current_page;
          }
        } catch (err) {
          console.log(err);
        }

        // loading overlay pagination
        this.pageRefresh = false;
        await this.$nextTick();
        this.pageRefresh = true;

        this.pageLoading = false;
      }
    },
    closeSidebar() {
      this.overlay = false;
      this.drawer = false;
      document.querySelector("html").classList.remove("on-scroll");
    },
    append() {
      for (let i = 0; i < 2; i++) {
        // this.items.push({
        //   title: `Item ${this.items.length}`,
        //   content: "Content",
        // });
      }
    },
    isFilterBtn() {
      this.overlay = !this.overlay;
      document.querySelector("html").classList.add("on-scroll");
    },
    isOverlay() {
      this.overlay = false;
      document.querySelector("html").classList.remove("on-scroll");
    },
    forceOverlay() {
      const windowSize = window.innerWidth;

      if (windowSize >= 1200) {
        this.overlay = false;
        this.drawer = false;
        document.querySelector("html").classList.remove("on-scroll");
      }
    },
  },
};
</script>

<style lang="scss">
@import "./style-art-categories.scss";
</style>
