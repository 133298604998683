<template>
  <div class="aa-artist-archive container-single">
    <div class="aa-artist-archive__banner">
      <v-container>
        <div class="aa-artist-archive__banner-wrapper">
          <h1>{{ this.fieldTitle }}</h1>
          <div class="ma-0" v-html="this.fieldDescription"></div>
        </div>
      </v-container>
    </div>

          <div class="aa-artist-archive__search">
            <v-container>
            <div class="aa-art-categories__list-search">
              <label
                >Can type artist name here
                <input
                  v-model="search"
                  placeholder="Search"
                  type="text"
                  @keydown.enter="$refs.searchBtn.click()"
                  :disabled="this.pageLoading"
                />
              </label>

              <div class="aa-art-categories__list-tools">
                <button
                  @click="isPagination"
                  ref="searchBtn"
                  class="btn btn-primary arrow"
                  :disabled="this.pageLoading"
                >
                  Search
                </button>
              </div>
            </div>
            </v-container>
            </div>


    <div class="aa-artist-archive__team container-out">
      <v-container>
        <div class="aa-artist-archive__team-wrapper">
          <div
            class="aa-artist-archive__team-item"
            v-for="artist in pageData"
            :key="artist.id"
          >
            <router-link
              :to="{
                name: 'artist',
                params: { artist: slugFormat(artist.name) },
              }"
            >
              <v-img
                :src="
                  artist.profile_image
                    ? $func.src(artist.profile_image)
                    : '/images/placehold.webp'
                "
                :lazy-src="'/images/placehold.webp'"
                aspect-ratio="1.4"
                cover
                height="220"
                width="220"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    color="red"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="red"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </router-link>
            <h3>
              <router-link
                :to="{
                  name: 'artist',
                  params: { artist: slugFormat(artist.name) },
                }"
              >
                {{ artist.name }}
              </router-link>
            </h3>
          </div>

          <v-row
            class="fill-height pa-md-16 aa-loading"
            align="center"
            justify="center"
            v-if="this.pageLoading && this.pageData"
          >
            <v-progress-circular
              indeterminate
              color="red"
              :size="100"
              :width="10"
              >Loading</v-progress-circular
            >
          </v-row>
        </div>

        <div class="v-pagination__inner" v-if="this.pageLength > 1">
          <v-pagination
            v-model="pageCurrent"
            :length="this.pageLength"
            :disabled="this.pageLoading"
            @input="pageCurrentPagination"
            :total-visible="6"
            color="none"
          ></v-pagination>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import ArtistsOur from "@/services/ArtistsOur";
//import ArtCategories from "@/services/ArtCategories.js";

export default {
  data: () => {
    return {
      fieldTitle: "",
      fieldDescription: "",
      fields: null,
      pageData: null,
      pageCurrent: 1,
      pageCurrentFlag: null,
      pageLength: null,
      pageLoading: false,
      search: "",
      searchGlobal: "",
      options: "",
      searchParam: "",
    };
  },
  created: async function() {
    try {
      const fields = await ArtistsOur.getArtistsOurField();
      this.fieldTitle = fields.title;
      this.fieldDescription = fields.description;
    } catch (err) {
      console.log(err);
    }

    try {
      const data = await ArtistsOur.getArtistsOur(this.pageCurrent);

      if (data) {
        this.pageData = data.data;
        this.pageLength = data.last_page;
        this.pageCurrentFlag = data.current_page;
      }
    } catch (err) {
      console.log(err);
    }

    this.$store.dispatch("loader/showLoader", {
      loading: false,
    });
  },
  methods: {
    async pageCurrentPagination(page) {
      if (this.pageCurrentFlag != page) {
        this.pageLoading = true;
        try {
          const data = await ArtistsOur.getArtistsOurWithSearch(this.pageCurrent, this.search, this.options);
          if (data) {
            this.pageData = data.data;
            this.pageLength = data.last_page;
            this.pageCurrentFlag = data.current_page;
          }
        } catch (err) {
          console.log(err);
        }

        this.pageLoading = false;
      }
    },
    slugFormat(el) {
      return el.replace(/\s+/g, "_").toLowerCase();
    },
    isFilterBtn() {
      this.overlay = !this.overlay;
      document.querySelector("html").classList.add("on-scroll");
    },
    async isPagination(page) {
      if (typeof page !== "number") {
        this.pageCurrent = 1;
      }
      if (this.search === "") {
        console.log("empty search");
        this.searchParam = "";
      } else {
        console.log("full search");
        this.searchParam = "&search=" + this.search;
      }

      if (this.pageCurrentFlag != page) {
        this.pageLoading = true;
        try {
          const image = await ArtistsOur.getArtistsOurWithSearch(
            this.pageCurrent,
            this.searchParam,
            this.options
          );

          if (image) {
            this.pageData = image.data;
            this.pageLength = image.last_page;
            this.pageCurrentFlag = image.current_page;
          }
        } catch (err) {
          console.log(err);
        }

        // loading overlay pagination
        this.pageRefresh = false;
        await this.$nextTick();
        this.pageRefresh = true;

        this.pageLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./style-artist-archive.scss";
</style>
