const privatePages = [
  "Login",
  "ReturnPolicy",
  "PrivacyPolicy",
  "Reset Password"
];

const seoData = pageAlias => {
  return {
    public: `<link rel="canonical" href="https://mdartgalleries.com${pageAlias}"/>
         <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1">`,
    private: `<meta name="robots" content="noindex, follow">`
  };
};

const getQueryData = to => {
  const queries = Object.keys(to.query);
  let uri = seoData(to.path);
  if (queries.length) {
    const queryString = queries.map(key => `${key}=${to.query[key]}`).join("&");
    uri = seoData(`${to.path}?${queryString}`);
  }
  return uri;
};

export default function seoTags({ to, next }) {
  const head = document.querySelector("head");
  const data = getQueryData(to);
  const tags = privatePages.includes(to.name) ? data.private : data.public;

  const existingTags = head.querySelectorAll(
    'meta[name="robots"], link[rel="canonical"]'
  );
  if (!existingTags.length) {
    const keywordsMetaTag = head.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {
      keywordsMetaTag.insertAdjacentHTML("afterend", tags);
    } else {
      head.insertAdjacentHTML("beforeend", tags);
    }
  }
  next();
}
