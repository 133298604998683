import * as API from "@/services/API";

const pickMetaFields = ({
  meta_description,
  meta_keywords,
  meta_title
} = {}) => ({
  meta_description: meta_description || "",
  meta_keywords: meta_keywords || "",
  meta_title: meta_title || ""
});

const getArtistPageSeo = async () => {
  return API.apiClient.get("/artists-get-fields");
};

const getProductsPageSeo = async () => {
  return API.apiClient.get("/products-get-fields");
};

const factoryMetaData = async (data, default_data) => {
  return {
    meta_title: data.meta_title
      ? data.meta_title
      : default_data.data.meta_title,
    meta_keywords: data.meta_keywords
      ? data.meta_keywords
      : default_data.data.meta_keywords,
    meta_description: data.meta_description
      ? data.meta_description
      : default_data.data.meta_description
  };
};

const replaceVariable = (meta_data, search, replace) => {
  return {
    meta_title:
      meta_data.meta_title && meta_data.meta_title.length
        ? meta_data.meta_title.replace(search, replace)
        : "",
    meta_keywords:
      meta_data.meta_keywords && meta_data.meta_keywords.length
        ? meta_data.meta_keywords.replace(search, replace)
        : "",
    meta_description:
      meta_data.meta_description && meta_data.meta_description.length
        ? meta_data.meta_description.replace(search, replace)
        : ""
  };
};

const factoryMethod = async (routeName, to = {}) => {
  let service;

  switch (routeName) {
    case "Home": {
      service = await import("@/services/HomepageFieldsService");
      const { data } = await service.default.getField();
      return pickMetaFields(data);
    }
    case "About": {
      service = await import("@/services/AboutUsFieldsService");
      const data = await service.default.getField();
      return pickMetaFields(data);
    }

    case "Categories": {
      service = await import("@/services/CategoriesFieldService.js");
      const data = await service.default.getCategoriesField();
      return pickMetaFields(data);
    }

    case "Contact": {
      service = await import("@/services/ContactUsService");
      const data = await service.default.getField();
      return pickMetaFields(data);
    }
    case "Artist Archive": {
      service = await import("@/services/ArtistsOur");
      const data = await service.default.getArtistsOurField();
      return pickMetaFields(data);
    }
    case "FAQ": {
      service = await import("@/services/FAQFieldsService");
      const { data } = await service.default.getField();
      return pickMetaFields(data);
    }
    case "artist": {
      service = await import("@/services/ArtistDetails");
      if (!to || !to.params || !to.params.artist) {
        return pickMetaFields({});
      }
      const data = await service.default.getArtistInfo(to.params.artist);
      const default_data = await getArtistPageSeo();
      const result = await factoryMetaData(data, default_data);
      return replaceVariable(result, "{user_name}", data.artist_name);
    }
    case "Order": {
      service = await import("@/services/ImageService");
      if ((!to && !to.query) || !to.query.image_id) {
        return pickMetaFields({});
      }
      const data = await service.default.getImage(to.query.image_id);
      const default_data = await getProductsPageSeo();
      let result = await factoryMetaData(data, default_data);
      result = replaceVariable(result, "{title}", data.name);
      return replaceVariable(result, "{author}", data.artist_name);
    }
    case "ReturnPolicy": {
      service = await import("@/services/ReturnPolicy");
      const data = await service.default.getReturnPolicyField();
      return pickMetaFields(data);
    }
    case "PrivacyPolicy": {
      service = await import("@/services/PrivacyPolicy");
      const data = await service.default.getPrivacyPolicyField();
      return pickMetaFields(data);
    }
    case "ArtCategories": {
      service = await import("@/services/ArtCategories");
      const data = await service.default.getFields();
      return pickMetaFields(data);
    }
    default:
      return {};
  }
};

export default factoryMethod;
